<template>
  <div>
    <div class="columns is-multiline">
      <div class="column is-12">
        <div class="columns">
          <div class="column is-2"></div>
          <div class="column is-8">
            <template v-if="data.leftSideNumber == 0">
              <div class="columns">
                <div
                  v-for="(number, index) in data.rightSideNumber + 1"
                  :key="index"
                  class="column"
                >
                  <b-field grouped position="is-centered">
                    {{ index }}
                  </b-field>
                </div>
              </div>
            </template>
            <template v-else-if="data.leftSideNumber == 1">
              <div class="columns">
                <div
                  v-for="(number, index) in data.rightSideNumber"
                  :key="index"
                  class="column"
                >
                  <b-field grouped position="is-centered">
                    {{ number }}
                  </b-field>
                </div>
              </div>
            </template>
          </div>
          <div class="column is-2"></div>
        </div>
        <ValidationProvider
          :vid="`linearscaletype${indexSection}${indexQuestion}`"
          name="Linear Scale"
          :rules="`${data.isRequired ? 'required' : ''}`"
          v-slot="{ errors }"
        >
          <div class="columns is-multiline">
            <div class="column is-2 no-translate has-text-right">
              {{ data.leftSide }}
            </div>
            <div class="column is-8">
              <template v-if="data.leftSideNumber == 0">
                <div class="columns">
                  <div
                    v-for="(number, index) in data.rightSideNumber + 1"
                    :key="index"
                    class="column"
                  >
                    <b-field grouped position="is-centered">
                      <b-radio
                        class="scale-width"
                        type="is-hcc"
                        v-model="linearScale"
                        :native-value="index"
                      ></b-radio>
                    </b-field>
                  </div>
                </div>
              </template>
              <template v-else-if="data.leftSideNumber == 1">
                <div class="columns">
                  <div
                    v-for="(number, index) in data.rightSideNumber"
                    :key="index"
                    class="column"
                  >
                    <b-field grouped position="is-centered">
                      <b-radio
                        class="scale-width"
                        type="is-hcc"
                        v-model="linearScale"
                        :native-value="number"
                      ></b-radio>
                    </b-field>
                  </div>
                </div>
              </template>
            </div>
            <div class="column is-2 notranslate">
              {{ data.rightSide }}
            </div>
            <div class="required column is-12">{{ errors[0] }}</div>
          </div>
          <info-storing-answer :question="data" @resend="$emit('resend-answer', data)"/>
        </ValidationProvider>
      </div>
    </div>
  </div>
</template>

<script>
import InfoStoringAnswer from '@/components/V2/Candidat/InfoStoringAnswer.vue'
export default {
  components:{
    InfoStoringAnswer,
  },
  props: {
    data: {
      default: () => {},
    },
    indexSection: {
      default: () => {},
    },
    indexQuestion: {
      default: () => {},
    },
    sectionId: {
      default: () => {},
    },
  },
  data() {
    return {
      linearScale: null,
      isLoad: false,
    }
  },
  mounted() {
    this.init(this.data)
  },
  watch: {
    data(val){
      this.linearScale = null
      this.init(val)
    },
    linearScale() {
      if (!this.isLoad) {
        this.submit()
      } else {
        this.isLoad = false
      }
    },
  },
  methods: {
    init(data){
      this.isLoad = true
      this.linearScale =
        data.answerList[0].description == null
          ? null
          : parseInt(data.answerList[0].description)
      if (this.linearScale == null) {
        this.isLoad = false
      }
    },
    submit() {
      let data = {
        newSectionId: this.sectionId,
        newQuestionId: this.data.id,
        newAnswerId: this.data.answerList[0].id,
        description: null,
        rating: this.linearScale,
        indexQuestion: 0,
        indexAnswer: 0,
      }

      this.$emit('clicked', data)
    },
    resetAnswer(){
      this.linearScale = null
    }
  },
}
</script>
<style>
.scale-width {
  width: 10px !important;
}
</style>
