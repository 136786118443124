var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"message-body-data"},[(_vm.rankingOption.length > 0 && _vm.rankingOption[0].type == 'text')?_c('span',[_vm._v(" Klik tahan pilihan dari kotak sebelah kiri, dan pindahkan ke kotak sebelah kanan. Setelah itu, urutkan pilihan Anda. "),_c('em',[_vm._v("(Click and hold a selection from the box on the left and move it to the box on the right. After transferring your selections, rank them in order of preference.)")])]):(_vm.rankingOption.length > 0 && _vm.rankingOption[0].type == 'image')?_c('span',[_vm._v(" Klik tahan pilihan dari kotak atas, dan pindahkan ke kotak bawah. Setelah itu, urutkan pilihan Anda. "),_c('em',[_vm._v("(Click and hold a selection from the box on the top and move it to the box on the bottom. After transferring your selections, rank them in order of preference.)")])]):_vm._e()]),_c('ValidationProvider',{style:((_vm.rankingOption.length > 0 && _vm.rankingOption[0].type == 'text') ||
      (_vm.answerRanking.length > 0 && _vm.answerRanking[0].type == 'text')
        ? "display: flex;flex-wrap: nowrap;flex-direction: row;justify-content: flex-start;align-items: stretch; margin: 10px;"
        : ""),attrs:{"vid":("rankingtype" + _vm.indexSection + _vm.indexQuestion),"name":"Ranking","rules":("" + (_vm.checkRulesData(
        _vm.data.isRequired,
        _vm.data.leftSideNumber,
        _vm.data.rightSideNumber
      )))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('draggable',{staticClass:"border-ranking-left is-variable list-group",style:(_vm.rankingOption.length > 0 && _vm.rankingOption[0].type == 'text'
          ? "flex-direction: column;padding: 10px;"
          : "flex-direction: row;"),attrs:{"sort":false,"ghost-class":"draggable-ghost","list":_vm.rankingOption,"group":("ranking1" + _vm.indexSection + _vm.indexQuestion)}},_vm._l((_vm.rankingOption),function(ranking,indexRanking){return _c('div',{key:indexRanking,staticClass:"list-item notranslate",style:(_vm.rankingOption.length > 0 && _vm.rankingOption[0].type == 'text'
            ? "width:100%; min-height: 18px;"
            : "width: 180px; height: 180px;")},[(ranking.type == 'text')?_c('div',{style:(_vm.rankingOption.length > 0 && _vm.rankingOption[0].type == 'text'
              ? "word-wrap: break-word; height: 100%; width: 100%; padding: 10px"
              : "word-wrap: break-word; height: 180px; width: 100%;")},[_vm._v(" "+_vm._s(ranking.description)+" ")]):_c('img',{staticClass:"is-align-self-center",staticStyle:{"max-height":"180px","max-width":"180px"},attrs:{"src":ranking.imagePath,"alt":"Question Image"}})])}),0),_c('div',[_c('br')]),_c('draggable',{staticClass:"border-ranking-right is-variable list-group",style:(_vm.answerRanking.length > 0 && _vm.answerRanking[0].type == 'text'
          ? "flex-direction: column;padding: 10px;"
          : "flex-direction: row;"),attrs:{"ghost-class":"draggable-ghost","group":("ranking1" + _vm.indexSection + _vm.indexQuestion)},model:{value:(_vm.answerRanking),callback:function ($$v) {_vm.answerRanking=$$v},expression:"answerRanking"}},_vm._l((_vm.answerRanking),function(ranking,indexRanking){return _c('div',{key:indexRanking,staticClass:"list-item notranslate",style:(_vm.answerRanking.length > 0 && _vm.answerRanking[0].type == 'text'
            ? "width:100%; min-height: 18px;"
            : "width: 180px; height: 180px;")},[(ranking.type == 'text')?_c('div',{style:(_vm.answerRanking.length > 0 && _vm.answerRanking[0].type == 'text'
              ? "word-wrap: break-word; height: 100%; width: 100%; padding: 10px;"
              : "word-wrap: break-word; height: 180px; width: 100%;")},[_vm._v(" "+_vm._s(indexRanking + 1)+". "+_vm._s(ranking.description)+" ")]):_c('img',{staticClass:"is-align-self-center",staticStyle:{"max-height":"180px","max-width":"180px"},attrs:{"src":ranking.imagePath,"alt":"Question Image"}})])}),0),_c('div',{staticClass:"required columns is-12"},[_c('div',{staticClass:"column is-12"},[_vm._v(" "+_vm._s(errors[0])+" ")])])]}}])}),_c('info-storing-answer',{attrs:{"question":_vm.data},on:{"resend":function($event){return _vm.$emit('resend-answer', _vm.data)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }