<template>
  <div>
    <ValidationProvider
      :vid="`starRatingType${indexSection}${indexQuestion}`"
      name="Star Rating"
      :rules="{ required: data.isRequired }"
      v-slot="{ errors }"
    >
      <b-rate v-model="rate" :max="data.answerList[0].rightSideNumber"></b-rate>
      <span class="required">{{ errors[0] }}</span>
    </ValidationProvider>
    <info-storing-answer :question="data" @resend="$emit('resend-answer', data)"/>
  </div>
</template>

<script>
import InfoStoringAnswer from '@/components/V2/Candidat/InfoStoringAnswer.vue'
export default {
  components:{
    InfoStoringAnswer,
  },
  props: {
    data: {
      default: () => {},
    },
    indexSection: {
      default: () => {},
    },
    indexQuestion: {
      default: () => {},
    },
    sectionId: {
      default: () => {},
    },
  },
  data() {
    return {
      rate: 0,
      isLoad: false,
    }
  },
  mounted() {
    this.init(this.data)
  },
  watch: {
    data(val){
      this.rate = 0
      this.init(val)
    },
    rate() {
      if (!this.isLoad) {
        this.submit()
      } else {
        this.isLoad = false
      }
    },
  },
  methods: {
    init(data){
      this.isLoad = true
      this.rate =
        data.answerList[0].description == null
          ? null
          : parseInt(data.answerList[0].description)
      if (this.rate == null) {
        this.isLoad = false
      }
    },
    submit() {
      let data = {
        newSectionId: this.sectionId,
        newQuestionId: this.data.id,
        newAnswerId: this.data.answerList[0].id,
        description: null,
        rating: this.rate,
        indexQuestion: 0,
        indexAnswer: 0,
      }

      this.$emit('clicked', data)
    },
    resetAnswer(){
      this.rate = 0
    }
  },
}
</script>
