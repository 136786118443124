<template>
	<div>
		<span v-if="question.sendingAnswerMessage != null">
			<span v-if="question.isSendingAnswer === false" style="color:red;">
				{{question.sendingAnswerMessage}}
			</span>
			<span v-else-if="question.isSendingAnswer === true">
				{{question.sendingAnswerMessage}}
			</span>
			<span v-else-if="question.isSendingAnswer === null" style="color:green;">
				{{question.sendingAnswerMessage}}
			</span>
		</span>
		<span v-if="question.isSendingAnswer === true">
			<b-icon size="is-small" style="height: 10px;" 
				icon="circle-notch" custom-class="fa-spin">
			</b-icon>
		</span>
		<span v-if="question.isSendingAnswer === false"
			style="color:red;">
			<b-button type="is-warning" size="is-small" @click="$emit('resend', question)">Kirim Ulang <em>(Resend)</em></b-button>
		</span>
	</div>
</template>

<script>
export default {
  props: {
		question: {
      default: () => {},
    },
	}
}
</script>