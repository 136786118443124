<template>
  <div>
    <div class="columns is-multiline" style="overflow-x: auto;">
      <div
        class="column is-12"
        v-if="this.newMatrix && this.newMatrix.length > 0"
      >
        <table style="width: 100%;">
          <tr v-for="indexRow in maxRow" :key="indexRow">
            <td
              v-for="indexColumn in maxColumn"
              :key="indexColumn"
              style="overflow-wrap: break-word; padding:5px"
              :style="{
                width:
                  indexColumn == 1
                    ? 100 -
                      (newMatrix.length > 0
                        ? newMatrix.reduce((totalRow, row) => {
                            return (
                              totalRow +
                              row.reduce((totalItem, item) => {
                                return (
                                  totalItem +
                                  (item && item.width
                                    ? parseInt(item.width) || 0
                                    : 0)
                                )
                              }, 0)
                            )
                          }, 0)
                        : 0) +
                      '%'
                    : newMatrix[indexRow - 1][indexColumn - 1]
                    ? newMatrix[indexRow - 1][indexColumn - 1].width + '%'
                    : '0%',
              }"
            >
              <span v-if="newMatrix != null">
                <span v-if="indexRow == 1 || indexColumn == 1">
                  <span
                    v-if="newMatrix[indexRow - 1][indexColumn - 1] != null"
                    class="notranslate"
                  >
                    {{ newMatrix[indexRow - 1][indexColumn - 1].description }}
                    {{
                      newMatrix[indexRow - 1][indexColumn - 1].width &&
                      newMatrix[indexRow - 1][indexColumn - 1].width > 0 &&
                      newMatrix[indexRow - 1][indexColumn - 1].isPercent == 1
                        ? '(%)'
                        : ''
                    }}
                  </span>
                </span>
                <span v-else>
                  <ValidationProvider
                    :vid="
                      `matrix${indexSection}${indexQuestion}${indexColumn -
                        1}${indexRow - 1}`
                    "
                    name="Text"
                    :rules="{ required: data.isRequired }"
                    v-slot="{ errors }"
                  >
                    <b-field :type="errors.length > 0 ? 'is-danger' : null">
                      <b-input
                        v-model="
                          newMatrix[indexRow - 1][indexColumn - 1].description
                        "
                        @blur="submit(newMatrix[indexRow - 1][indexColumn - 1])"
                        :class="errMatrix ? 'border-red' : ''"
                        :type="
                          newMatrix[indexRow - 1][indexColumn - 1].type ==
                          'percent'
                            ? 'number'
                            : newMatrix[indexRow - 1][indexColumn - 1].type
                        "
                      ></b-input>
                    </b-field>
                    <span class="required">{{ errors[0] }}</span>
                  </ValidationProvider>
                </span>
              </span>
            </td>
          </tr>
        </table>

        <span class="has-text-info" v-if="totalText != ''">
          {{ totalText }}
        </span>

        <info-storing-answer
          :question="data"
          @resend="$emit('resend-answer', data)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import InfoStoringAnswer from '@/components/V2/Candidat/InfoStoringAnswer.vue'
export default {
  components: {
    InfoStoringAnswer,
  },
  props: {
    data: {
      default: () => {},
    },
    indexSection: {
      default: () => {},
    },
    indexQuestion: {
      default: () => {},
    },
    sectionId: {
      default: () => {},
    },
  },
  data() {
    return {
      matrix: [],
      isLoad: false,

      maxRow: 0,
      totalText: '',
      maxColumn: 0,
      newMatrix: [],
      errMatrix: false,
    }
  },
  watch: {
    data(val) {
      this.matrix = []
      this.newMatrix = []
      this.maxRow = 0
      this.maxColumn = 0
      this.init(val)
    },
  },
  mounted() {
    this.init(this.data)
  },
  methods: {
    init(data) {
      this.isLoad = true
      this.errMatrix = false
      this.matrix = data.answerList

      if (this.matrix.length > 0) {
        const filterMatrix = this.matrix.filter(
          e => e.id == null && e.type != null && e.description != null
        )
        let total = 0
        filterMatrix.forEach(f => {
          total += parseInt(f.description)
        })
        if (total > 0) {
          let totalIsi = total.toString()
          totalIsi += this.matrix[0].isPercent == 1 ? '%' : ''
          let maxVal = this.matrix[0].max.toString()
          maxVal += this.matrix[0].isPercent == 1 ? '%' : ''
          this.totalText =
            'Total Pengisian Anda ' + totalIsi + ' dari ' + maxVal
        }
      }
      this.filterTable()
    },
    submit(val) {
      this.totalText = ''
      this.errMatrix = false
      if (val.type != 'text' && this.matrix.length > 0) {
        let errShow = false
        if (parseInt(val.description) < this.matrix[0].min) {
          this.errMatrix = true
          errShow = true
          this.$buefy.dialog.alert({
            title: 'Attention!',
            message: `Nilai minimal yang dapat Anda isi adalah ${this.matrix[0].min}, silahkan isi kembali. `,
            type: 'is-warning',
            ariaModal: true,
            canCancel: false,
            onConfirm: () => null,
          })
          return
        } else if (parseInt(val.description) > this.matrix[0].max) {
          this.errMatrix = true
          errShow = true
          this.$buefy.dialog.alert({
            title: 'Attention!',
            message: `Nilai maksimum yang dapat Anda isi adalah ${this.matrix[0].max}, silahkan isi kembali. `,
            type: 'is-warning',
            ariaModal: true,
            canCancel: false,
            onConfirm: () => null,
          })
          return
        }
        const filterMatrix = this.matrix.filter(
          e => e.id == null && e.type != null && e.description != null
        )
        const countAnswer = this.matrix.filter(
          e => e.id == null && e.type != null
        )
        let total = 0
        filterMatrix.forEach(f => {
          total += parseInt(f.description)
        })
        if (total > 0) {
          let totalIsi = total.toString()
          totalIsi += val.isPercent == 1 ? '%' : ''
          let maxVal = val.max.toString()
          maxVal += val.isPercent == 1 ? '%' : ''
          this.totalText =
            'Total Pengisian Anda ' + totalIsi + ' dari ' + maxVal
        }

        if (filterMatrix.length == countAnswer.length) {
          let totalAll = 0
          filterMatrix.forEach(f => {
            totalAll += parseInt(f.description)
          })

          let totalIsiAll = totalAll.toString()
          totalIsiAll += val.isPercent == 1 ? '%' : ''
          let maxVal = val.max.toString()
          maxVal += val.isPercent == 1 ? '%' : ''

          if (totalAll < this.matrix[0].max && !errShow) {
            this.errMatrix = true
            errShow = true
            this.$buefy.dialog.alert({
              title: 'Attention!',
              message:
                'Total Pengisian Anda saat ini <span class="has-text-danger has-text-weight-bold">' +
                totalIsiAll +
                '</span> dari <span class="has-text-info has-text-weight-bold">' +
                maxVal +
                '</span>,<br/> Pastikan jumlah jawaban anda sama dengan <span class="has-text-info has-text-weight-bold">' +
                maxVal +
                '</span>',
              type: 'is-warning',
              ariaModal: true,
              canCancel: false,
              onConfirm: () => null,
            })
          } else if (totalAll > this.matrix[0].max && !errShow) {
            this.errMatrix = true
            errShow = true
            this.$buefy.dialog.alert({
              title: 'Attention!',
              message:
                'Total Pengisian Anda saat ini <span class="has-text-danger has-text-weight-bold">' +
                totalIsiAll +
                '</span> dari <span class="has-text-info has-text-weight-bold">' +
                maxVal +
                '</span>,<br/> pastikan jumlah jawaban anda sama dengan <span class="has-text-info has-text-weight-bold">' +
                maxVal +
                '</span>',
              type: 'is-warning',
              ariaModal: true,
              canCancel: false,
              onConfirm: () => null,
            })
          }
        }
      }
      let data = {
        errMatrix: this.errMatrix,
        newSectionId: this.sectionId,
        newQuestionId: this.data.id,
        newAnswerId: val,
        description: val.description,
        rating: 0,
        indexQuestion: 0,
        indexAnswer: 0,
        column: val.column,
        row: val.row,
      }
      this.$emit('clicked', data)
    },
    filterTable() {
      this.maxRow = this.matrix.sort((a, b) => b.row - a.row)[0].row
      this.maxColumn = this.matrix.sort((a, b) => b.column - a.column)[0].column
      for (var i = 1; i <= this.maxRow; i++) {
        let y = new Array()
        for (var j = 1; j <= this.maxColumn; j++) {
          var found = this.matrix.find(function(x) {
            return x.row === i && x.column === j
          })
          if (found) {
            y.push(found)
          } else {
            y.push(null)
          }
        }
        this.newMatrix.push(y)
      }
    },
    resetAnswer() {
      this.newMatrix = []
    },
  },
}
</script>
<style lang="scss" scoped>
.border-red {
  border: 1px solid #e74747;
}
.matrix {
  max-width: 1032px;
  margin: 0px auto;
}
</style>
