<template>
  <div>
    <template v-if="!data.isExample">
      <ValidationProvider
        :vid="`textType${indexSection}${indexQuestion}`"
        name="Text"
        :rules="{ required: data.isRequired }"
        v-slot="{ errors }"
      >
        <b-field :type="errors.length > 0 ? 'is-danger' : null">
          <b-input
            type="textarea"
            v-model="description"
            @blur="submit()"
          ></b-input>
        </b-field>
        <span class="required">{{ errors[0] }}</span>
      </ValidationProvider>
    </template>
    <template v-else>
      {{ data.answerList[0].description }}
    </template>
    <info-storing-answer :question="data" @resend="$emit('resend-answer', data)"/>
  </div>
</template>

<script>
import InfoStoringAnswer from '@/components/V2/Candidat/InfoStoringAnswer.vue'
export default {
  name: 'answer-type-essay',
  components:{
    InfoStoringAnswer,
  },
  props: {
    data: {
      default: () => {},
    },
    indexSection: {
      default: () => {},
    },
    sectionId: {
      default: () => {},
    },
    indexQuestion: {
      default: () => {},
    },
  },
  data() {
    return {
      description: null,
      isLoad: false,
    }
  },
  watch:{
    data(val){
      this.description = null
      this.init(val)
    }
  },
  mounted() {
    this.init(this.data)
  },
  methods: {
    init(data){
      this.isLoad = true
      this.description = data.answerList[0].description
      if (this.description == null) {
        this.isLoad = false
      }
    },
    submit() {
      let data = {
        newSectionId: this.sectionId,
        newQuestionId: this.data.id,
        newAnswerId: this.data.answerList[0].id,
        description: this.description,
        rating: 0,
        indexQuestion: 0,
        indexAnswer: 0,
      }

      this.$emit('clicked', data)
    },
    resetAnswer(){
      this.description = null
    }
  },
}
</script>
