var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"columns is-multiline",staticStyle:{"overflow-x":"auto"}},[(this.newMatrix && this.newMatrix.length > 0)?_c('div',{staticClass:"column is-12"},[_c('table',{staticStyle:{"width":"100%"}},_vm._l((_vm.maxRow),function(indexRow){return _c('tr',{key:indexRow},_vm._l((_vm.maxColumn),function(indexColumn){return _c('td',{key:indexColumn,staticStyle:{"overflow-wrap":"break-word","padding":"5px"},style:({
              width:
                indexColumn == 1
                  ? 100 -
                    (_vm.newMatrix.length > 0
                      ? _vm.newMatrix.reduce(function (totalRow, row) {
                          return (
                            totalRow +
                            row.reduce(function (totalItem, item) {
                              return (
                                totalItem +
                                (item && item.width
                                  ? parseInt(item.width) || 0
                                  : 0)
                              )
                            }, 0)
                          )
                        }, 0)
                      : 0) +
                    '%'
                  : _vm.newMatrix[indexRow - 1][indexColumn - 1]
                  ? _vm.newMatrix[indexRow - 1][indexColumn - 1].width + '%'
                  : '0%',
            })},[(_vm.newMatrix != null)?_c('span',[(indexRow == 1 || indexColumn == 1)?_c('span',[(_vm.newMatrix[indexRow - 1][indexColumn - 1] != null)?_c('span',{staticClass:"notranslate"},[_vm._v(" "+_vm._s(_vm.newMatrix[indexRow - 1][indexColumn - 1].description)+" "+_vm._s(_vm.newMatrix[indexRow - 1][indexColumn - 1].width && _vm.newMatrix[indexRow - 1][indexColumn - 1].width > 0 && _vm.newMatrix[indexRow - 1][indexColumn - 1].isPercent == 1 ? '(%)' : '')+" ")]):_vm._e()]):_c('span',[_c('ValidationProvider',{attrs:{"vid":("matrix" + _vm.indexSection + _vm.indexQuestion + (indexColumn -
                      1) + (indexRow - 1)),"name":"Text","rules":{ required: _vm.data.isRequired }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-field',{attrs:{"type":errors.length > 0 ? 'is-danger' : null}},[_c('b-input',{class:_vm.errMatrix ? 'border-red' : '',attrs:{"type":_vm.newMatrix[indexRow - 1][indexColumn - 1].type ==
                        'percent'
                          ? 'number'
                          : _vm.newMatrix[indexRow - 1][indexColumn - 1].type},on:{"blur":function($event){return _vm.submit(_vm.newMatrix[indexRow - 1][indexColumn - 1])}},model:{value:(
                        _vm.newMatrix[indexRow - 1][indexColumn - 1].description
                      ),callback:function ($$v) {_vm.$set(_vm.newMatrix[indexRow - 1][indexColumn - 1], "description", $$v)},expression:"\n                        newMatrix[indexRow - 1][indexColumn - 1].description\n                      "}})],1),_c('span',{staticClass:"required"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]):_vm._e()])}),0)}),0),(_vm.totalText != '')?_c('span',{staticClass:"has-text-info"},[_vm._v(" "+_vm._s(_vm.totalText)+" ")]):_vm._e(),_c('info-storing-answer',{attrs:{"question":_vm.data},on:{"resend":function($event){return _vm.$emit('resend-answer', _vm.data)}}})],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }