<template>
  <div>
    <div class="message-body-data">
      <span v-if="rankingOption.length > 0 && rankingOption[0].type == 'text'">
        Klik tahan pilihan dari kotak sebelah kiri, dan pindahkan ke kotak sebelah kanan. Setelah itu, urutkan pilihan Anda. <em>(Click and hold a selection from the box on the left and move it to the box on the right. After transferring your selections, rank them in order of preference.)</em>
      </span>
      <span
        v-else-if="rankingOption.length > 0 && rankingOption[0].type == 'image'"
      >
        Klik tahan pilihan dari kotak atas, dan pindahkan ke kotak bawah. Setelah itu, urutkan pilihan Anda. <em>(Click and hold a selection from the box on the top and move it to the box on the bottom. After transferring your selections, rank them in order of preference.)</em>
      </span>
    </div>
    <ValidationProvider
      :vid="`rankingtype${indexSection}${indexQuestion}`"
      name="Ranking"
      :rules="
        `${checkRulesData(
          data.isRequired,
          data.leftSideNumber,
          data.rightSideNumber
        )}`
      "
      v-slot="{ errors }"
      :style="
        (rankingOption.length > 0 && rankingOption[0].type == 'text') ||
        (answerRanking.length > 0 && answerRanking[0].type == 'text')
          ? `display: flex;flex-wrap: nowrap;flex-direction: row;justify-content: flex-start;align-items: stretch; margin: 10px;`
          : ``
      "
    >
      <!-- {{ rankingOption.length > 0 ? rankingOption[0].type : '0' }} -->
      <!-- For list question -->
      <draggable
        :sort="false"
        class="border-ranking-left is-variable list-group"
        ghost-class="draggable-ghost"
        :list="rankingOption"
        :group="`ranking1${indexSection}${indexQuestion}`"
        :style="
          rankingOption.length > 0 && rankingOption[0].type == 'text'
            ? `flex-direction: column;padding: 10px;`
            : `flex-direction: row;`
        "
      >
        <div
          v-for="(ranking, indexRanking) in rankingOption"
          :key="indexRanking"
          class="list-item notranslate"
          :style="
            rankingOption.length > 0 && rankingOption[0].type == 'text'
              ? `width:100%; min-height: 18px;`
              : `width: 180px; height: 180px;`
          "
        >
          <div
            :style="
              rankingOption.length > 0 && rankingOption[0].type == 'text'
                ? `word-wrap: break-word; height: 100%; width: 100%; padding: 10px`
                : `word-wrap: break-word; height: 180px; width: 100%;`
            "
            v-if="ranking.type == 'text'"
          >
            {{ ranking.description }}
          </div>
          <!-- {{ ranking.imagePath }} -->
          <img
            v-else
            :src="ranking.imagePath"
            style="max-height: 180px; max-width: 180px;"
            alt="Question Image"
            class="is-align-self-center"
          />
          <!-- <b-icon
            size="is-small"
            icon="level-down-alt"
            style="height: 10px;"
            class="is-align-self-center"
          ></b-icon> -->
        </div>
      </draggable>

      <div><br /></div>
      <!-- For list answer -->
      <draggable
        v-model="answerRanking"
        class="border-ranking-right is-variable list-group"
        ghost-class="draggable-ghost"
        :group="`ranking1${indexSection}${indexQuestion}`"
        :style="
          answerRanking.length > 0 && answerRanking[0].type == 'text'
            ? `flex-direction: column;padding: 10px;`
            : `flex-direction: row;`
        "
      >
        <div
          v-for="(ranking, indexRanking) in answerRanking"
          :key="indexRanking"
          class="list-item notranslate"
          :style="
            answerRanking.length > 0 && answerRanking[0].type == 'text'
              ? `width:100%; min-height: 18px;`
              : `width: 180px; height: 180px;`
          "
        >
          <div
            :style="
              answerRanking.length > 0 && answerRanking[0].type == 'text'
                ? `word-wrap: break-word; height: 100%; width: 100%; padding: 10px;`
                : `word-wrap: break-word; height: 180px; width: 100%;`
            "
            v-if="ranking.type == 'text'"
          >
            {{ indexRanking + 1 }}. {{ ranking.description }}
          </div>
          <!-- {{ ranking.imagePath }} -->
          <img
            v-else
            :src="ranking.imagePath"
            style="max-height: 180px; max-width: 180px;"
            alt="Question Image"
            class="is-align-self-center"
          />
          <!-- <b-icon
            size="is-small"
            icon="level-down-alt"
            style="height: 10px;"
            class="is-align-self-center"
          ></b-icon> -->
        </div>
      </draggable>
      <div class="required columns is-12">
        <div class="column is-12">
          {{ errors[0] }}
        </div>
      </div>
    </ValidationProvider>
    <info-storing-answer :question="data" @resend="$emit('resend-answer', data)"/>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import InfoStoringAnswer from '@/components/V2/Candidat/InfoStoringAnswer.vue'

export default {
  components: {
    draggable,
    InfoStoringAnswer,
  },
  props: {
    data: {
      default: () => {},
    },
    indexSection: {
      default: () => {},
    },
    indexQuestion: {
      default: () => {},
    },
    sectionId: {
      default: () => {},
    },
  },
  data() {
    return {
      rankingOption: [],
      answerRanking: [],
      isLoad: false,
    }
  },
  mounted() {
    this.init(this.data)
  },
  watch: {
    data(val){
      this.rankingOption = []
      this.answerRanking = []
      this.init(val)
    },
    answerRanking(val) {
      if (!this.isLoad) {
        this.submit(val)
      } else {
        this.isLoad = false
      }
    },
  },
  methods: {
    init(data){
      // if (data.answer == '' || data.answer == null) {
      //   data.answer = []
      // }
      this.isLoad = true
      data.answerList.map(answer => {
        function compareQuestion(a, b) {
          if (a.indexQuestion < b.indexQuestion) return -1
          if (a.indexQuestion > b.indexQuestion) return 1
          return 0
        }
        if (answer.indexQuestion > 0) {
          this.rankingOption.sort(compareQuestion)
          answer.imagePath = process.env.VUE_APP_BACKEND_URL + answer.imagePath
          this.rankingOption.push(answer)
        }

        function compareAnswer(a, b) {
          if (a.indexQuestion < b.indexQuestion) return -1
          if (a.indexQuestion > b.indexQuestion) return 1
          return 0
        }
        if (answer.indexAnswer > 0) {
          this.answerRanking.sort(compareAnswer)
          this.answerRanking.push(answer)
        }
      })

      if (this.answerRanking.length == 0) {
        this.isLoad = false
      }
    },
    submit(val) {
      let data = {
        newSectionId: this.sectionId,
        newQuestionId: this.data.id,
        newAnswerId: val,
        description: null,
        rating: 0,
        indexQuestion: this.rankingOption,
        indexAnswer: this.answerRanking,
      }

      this.$emit('clicked', data)
    },
    checkRulesData(isRequired, minimumNumber, maximumNumber) {
      let rules = ``
      if (isRequired) {
        rules = `requiredarray`
      }

      if (minimumNumber != null) {
        if (rules != ``) {
          rules = rules + `|`
        }
        rules = rules + `checkboxmin:${minimumNumber}`
      }

      if (maximumNumber != null) {
        if (rules != ``) {
          rules = rules + `|`
        }
        rules = rules + `checkboxmax:${maximumNumber}`
      }

      return rules
    },
    resetAnswer(){
      this.answerRanking = []
    }
  },
}
</script>
<style scoped>
.border-ranking-left {
  /* border: 1px solid black; */
  width: 100% !important;
  min-height: 200px !important;
  background-color: #ebebeb;
}
.border-ranking-right {
  border: 1px solid #7e7e7e;
  min-height: 200px !important;
  width: 100% !important;
}
.message-body-data {
  color: #7e7e7e;
  font-size: 12px;
}
.box-answer {
  /* border: 1px solid black; */
  margin-right: 3%;
  margin-bottom: 1%;
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1);
  color: #4a4a4a;
  padding-top: 0.25px;
  padding-bottom: 0.25px;
  padding-left: 1rem;
  padding-right: 0.5rem;
  /* display: block; */
}
.list-group{
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center; 
  margin: 10px;
}
.list-group .list-item{
  cursor:move;
  margin: 10px; 
  background-color:white; 
  display: flex; 
  flex-direction: column;
}
.draggable-ghost{
  opacity: 0.5;
  background: #00B79F;
}
</style>
